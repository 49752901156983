import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Habit Tracker App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/habit-tracker-mobile-app/"
    metaDescription="Looking to design your own habit tracking app? Habitat, our mobile app design template is here to streamline your project. Try now with Uizard Pro."
    description="
    h2:Habitat; our habit tracker app design template for mobile
    <br/>
    Say hello to Habitat, our brand-new mobile app template! If you're planning on working on your own habit tracker app design, then we have the perfect <a:https://uizard.io/prototyping/>UX/UI prototyping</a> solution for you. Our templates come with all the UI considerations baked right in, meaning you can focus on the stuff that really matters.
    <br/>
    h3:Make endless customization your latest habit
    <br/>
    Our <a:https://uizard.io/templates/mobile-app-templates/>mobile app templates</a> are built with customization in mind, helping you to truly bring your own vision to life. To get started with Habitat, simply sign up to Uizard Pro, navigate to our mobile template collection and select the template. From here, you can use the template as it is right out of the box or, you can update, edit, swap screens in and out, and even convert the template to a more basic wireframe.
    <br/>
    h3:Get into the habit of rapid design
    <br/>
    Uizard is designed for speed. We are here to help you realize your vision in minutes and bring a fully functional design prototype to life rapidly. Sign up to today and turn your design dreams into design reality with Uizard.
    "
    pages={[
      "Refined and elegant log-in and homepage screens to set the stage",
      "A smart onboarding flow to demonstrate how your user will begin tracking their habits",
      "Calendar interface to keep track of tasks and challenges",
      "An analytics screen for your prospective users to keep track of their data",
    ]}
    projectCode="lrxaWyp3BySBbEKYrl0q"
    img1={data.image1.childImageSharp}
    img1alt="mobile habit app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile habit app design onboarding screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile habit app design stats screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile habit app design review screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile habit app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/habit-tracker-mobile-app/habit-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/habit-tracker-mobile-app/habit-app-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/habit-tracker-mobile-app/habit-app-stats.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/habit-tracker-mobile-app/habit-app-review.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/habit-tracker-mobile-app/habit-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
